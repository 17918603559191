import React from 'react';
import letsFintechRocket from '../../../images/lets-fintech/lets-fintech-rocket.svg';

const LetsFintechRocket = props => {
  return (
    <img src={letsFintechRocket} alt="" {...props} />
  );
};

export default LetsFintechRocket;
