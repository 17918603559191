import React from 'react';
import letsFintechIcon1 from '../../../images/lets-fintech/lets-fintech-icon-1.svg';

const LetsFintechIcon1 = props => {
  return (
    <img src={letsFintechIcon1} alt="" {...props} />
  );
};

export default LetsFintechIcon1;
