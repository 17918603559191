import React from 'react';
import letsFintechIcon4 from '../../../images/lets-fintech/lets-fintech-icon-4.svg';

const LetsFintechIcon4 = props => {
  return (
    <img src={letsFintechIcon4} alt="" {...props} />
  );
};

export default LetsFintechIcon4;
