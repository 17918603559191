import React from 'react';
import letsFintechIcon3 from '../../../images/lets-fintech/lets-fintech-icon-3.svg';

const LetsFintechIcon3 = props => {
  return (
    <img src={letsFintechIcon3} alt="" {...props} />
  );
};

export default LetsFintechIcon3;
