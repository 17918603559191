import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const LetsFintechGrzegorzPawlicki = props => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "lets-fintech/lets-fintech-grzegorz-pawlicki.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return <Img {...props} fluid={data.placeholderImage.childImageSharp.fluid} />;
};

export default LetsFintechGrzegorzPawlicki;
