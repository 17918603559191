import React from 'react';
import letsFintechIcon2 from '../../../images/lets-fintech/lets-fintech-icon-2.svg';

const LetsFintechIcon2 = props => {
  return (
    <img src={letsFintechIcon2} alt="" {...props} />
  );
};

export default LetsFintechIcon2;
