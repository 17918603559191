import React from 'react';

import withLayout from '../components/withLayout';
import Heading from '../components/molecules/Heading';
import { FaArrowRight, FaSignature } from 'react-icons/all';
import styled from 'styled-components';
import breakpoints from '../utils/breakpoints';
import { containerStyles } from '../utils/styles';
import LetsFintech1 from '../components/images/lets-fintech/LetsFintech1';
import { FormattedMessage, FormattedHTMLMessage, useIntl } from 'react-intl';
import LetsFintechIcon1 from '../components/images/lets-fintech/LetsFintechIcon1';
import LetsFintechIcon2 from '../components/images/lets-fintech/LetsFintechIcon2';
import LetsFintechIcon3 from '../components/images/lets-fintech/LetsFintechIcon3';
import LetsFintechIcon4 from '../components/images/lets-fintech/LetsFintechIcon4';
import LetsFintechRocket from '../components/images/lets-fintech/LetsFintechRocket';
import LetsFintechGrzegorzPawlicki from '../components/images/lets-fintech/LetsFintechGrzegorzPawlicki';
import LetsFintechTeam from '../components/images/lets-fintech/LetsFintechTeam';
import fonts from '../utils/fonts';
import Section from '../components/atoms/Section';
import logo1 from '../images/lets-fintech/lets-fintech-logo-1.png'
import logo2 from '../images/lets-fintech/lets-fintech-logo-2.png'
import logo3 from '../images/lets-fintech/lets-fintech-logo-3.png'
import logo4 from '../images/lets-fintech/lets-fintech-logo-4.png'
import logo5 from '../images/lets-fintech/lets-fintech-logo-5.png'
import logo6 from '../images/lets-fintech/lets-fintech-logo-6.png'
import logo7 from '../images/lets-fintech/lets-fintech-logo-7.png'

const Container = styled.div`
  ${containerStyles};

  @media ${breakpoints.lg} {
    margin: 0 auto;
  }
`;

const StyledSection = styled(Section)`
  position: relative;
`;

const Button = styled.a`
  align-items: center;
  background: #e42229;
  border: 0;
  border-radius: 0.625rem 0 0.625rem 0;
  color: #ffffff;
  display: inline-flex;
  font-family: ${fonts.barlowCondensed};
  font-size: 1.125rem;
  font-weight: 600;
  height: 2.8125rem;
  line-height: 1.375rem;
  padding: 0 1.125rem;
  text-decoration: none;

  @media ${breakpoints.lg} {
    font-size: 1.5rem;
    height: 3.75rem;
    padding-left: 1.625rem;
  }
`;

const ButtonIcon = styled(FaArrowRight)`
  font-size 1rem;
  margin-left: 0.875rem;

  @media ${breakpoints.lg} {
    font-size 1.625rem;
    margin-left: 1.875rem;
  }
`;

const Separator = styled.div`
  background-image: linear-gradient(
    to right,
    #d9d9d9 0%,
    rgba(238, 238, 238, 0) 100%
  );
  height: 0.125rem;

  @media ${breakpoints.lg} {
    background-image: linear-gradient(
      to right,
      rgba(217, 217, 217, 0.0001) 0.05%,
      rgba(218, 218, 218, 0.4626) 10.28%,
      rgba(219, 219, 219, 0.5874) 78.72%,
      rgba(238, 238, 238, 0.7837) 99.92%
    );
    height: 0.0625rem;
  }
`;

const Rocket = styled.div`
  flex: 0 0 auto;
  margin-right: 3.125rem;
  position: relative;
  width: 4.375rem;

  @media ${breakpoints.lg} {
    margin-right: 3.75rem;
    width: 6.25rem;
  }

  &::before {
    background: #ededed;
    bottom: 1.25rem;
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    top: 1rem;
    transform: translateX(-50%);
    width: 0.1875rem;

    @media ${breakpoints.lg} {
      bottom: 1.875rem;
      top: 1.375rem;
      width: 0.25rem;
    }
  }
`;

const RocketIcon = styled(LetsFintechRocket)`
  left: 0;
  position: absolute;
  top: 0.375rem;
  width: 4.375rem;

  @media ${breakpoints.lg} {
    top: 1rem;
    width: 6.25rem;
  }
`;

const Section1 = styled.div`
  padding-bottom: 3.75rem;

  @media ${breakpoints.lg} {
    display: flex;
  }
`;

const Section1Button = styled.div`
  margin-top: 1.875rem;

  @media ${breakpoints.lg} {
    margin-top: 3.75rem;
  }
`;

const Section1Content = styled.div`
  @media ${breakpoints.lg} {
    order: 1;
    width: 60%;
  }

  @media ${breakpoints.xl} {
    width: 50%;
  }
`;

const Section1Image = styled.div`
  margin: -1.875rem auto 1.875rem;
  max-width: 27.5rem;
  padding: 0 2.5rem;
  text-align: center;

  @media ${breakpoints.lg} {
    margin-top: -4.5rem;
    order: 2;
    width: 40%;
  }

  @media ${breakpoints.xl} {
    width: 50%;
  }
`;

const Section1Text = styled.div`
  color: #000010;
  font-size: 1.0625rem;
  line-height: 1.5625rem;

  p {
    margin: 0 0 1.25rem 0;
  }

  strong {
    font-weight: 600;
  }
`;

const Section2 = styled.div`
  padding-bottom: 3.125rem;
  padding-top: 3.75rem;

  @media ${breakpoints.lg} {
    padding-bottom: 5.625rem;
    padding-top: 5.625rem;
  }
`;

const Section2Heading = styled.h2`
  color: #004a99;
  font-family: ${fonts.pko};
  font-size: 1.25rem;
  line-height: 1.25rem;
  margin: 0;

  @media ${breakpoints.lg} {
    color: #065ec9;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 2.75rem;
  }
`;

const Section2Icon = styled.div`
  padding: 0 1.25rem;

  @media ${breakpoints.lg} {
    flex: 0 0 auto;
    margin-right: 1.875rem;
    padding: 0;
    width: 6.25rem;
  }
`;

const Section2Item = styled.div`
  margin-top: 1.875rem;
  max-width: 12.5rem;
  padding: 0 1.25rem 0.625rem;
  text-align: center;
  width: 50%;

  @media ${breakpoints.lg} {
    align-items: center;
    display: flex;
    margin-top: 5.625rem;
    max-width: none;
    padding-bottom: 0;
    text-align: left;
    width: 33.33%;
  }

  @media ${breakpoints.xl} {
    width: 25%;
  }
`;

const Section2List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.25rem;

  @media ${breakpoints.xl} {
    flex-wrap: no-wrap;
  }
`;

const Section2Text = styled.div`
  font-family: ${fonts.barlowCondensed};
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1rem;
  margin-top: 0.8125rem;
  padding: 0 0.625rem;

  @media ${breakpoints.lg} {
    font-size: 1.5rem;
    line-height: 1.375rem;
    margin: 0;
    padding: 0;
  }
`;

const Section3 = styled.div`
  padding-bottom: 2.5rem;
  padding-top: 3.75rem;

  @media ${breakpoints.lg} {
    align-items: flex-start;
    display: flex;
    padding-bottom: 7.5rem;
    padding-top: 7.5rem;
  }
`;

const Section3Column = styled.div`
  margin-top: 3.75rem;

  @media ${breakpoints.md} {
    margin-right: 3.125rem;
    max-width: 14.375rem;
  }
`;

const Section3Content = styled.div`
  @media ${breakpoints.lg} {
    order: 2;
  }
`;

const Section3ColumnText = styled.div`
  font-family: ${fonts.barlowCondensed};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.375rem;
  margin-bottom: 1.875rem;

  @media ${breakpoints.lg} {
    margin-bottom: 2.8125rem;
  }
`;

const Section3Heading = styled.h2`
  color: #000010;
  font-family: ${fonts.pko};
  font-size: 2.5rem;
  line-height: 2.8125rem;
  margin: 0 0 2.5rem;

  @media ${breakpoints.lg} {
    font-size: 4.375rem;
    line-height: 5rem;
    margin: 0 0 3.125rem;
  }
`;

const Section3Info = styled.div`
  display: flex;
  padding-top: 4.6875rem;

  @media ${breakpoints.lg} {
    flex: 0 0 auto;
    margin-right: 9.0625rem;
    order: 1;
    padding-top: 0;
    width: 22.5rem;
  }
`;

const Section3InfoItem = styled.div`
  font-family: ${fonts.barlowCondensed};
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1rem;
  margin-bottom: 1.25rem;

  @media ${breakpoints.lg} {
    font-size: 1.5rem;
    line-height: 1.375rem;
    margin-bottom: 1.875rem;
  }

  strong {
    color: #065ec9;
    display: block;
    font-family: ${fonts.pko};
    font-size: 3.0625rem;
    font-weight: 700;
    line-height: 3.5rem;

    @media ${breakpoints.lg} {
      font-size: 4.375rem;
      line-height: 5rem;
    }
  }
`;

const Section3InfoList = styled.div`
  flex: 1 1 auto;
`;

const Section3Row = styled.div`
  @media ${breakpoints.md} {
    display: flex;
  }
`;

const Section3Text = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;

  @media ${breakpoints.lg} {
    font-size: 1.0625rem;
    line-height: 1.5625rem;
  }

  strong {
    font-weight: 600;
  }
`;

const Section4 = styled.div`
  padding-bottom: 3.75rem;
  padding-top: 3.75rem;

  @media ${breakpoints.lg} {
    align-items: center;
    display: flex;
    padding-bottom: 7.5rem;
    padding-top: 5.625rem;
  }
`;

const Section4Image = styled.div`
  max-width: 13rem;
  border-radius: 50%;
  overflow: hidden;

  @media ${breakpoints.lg} {
    flex: 0 0 auto;
    margin-right: 7.5rem;
    max-width: none;
    width: 17.5rem;
  }
`;

const Section4Text = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 4.375rem;

  @media ${breakpoints.lg} {
    font-size: 1.0625rem;
    line-height: 1.5625rem;
    margin-top: 0;
  }

  em {
    color: #004a99;
    font-style: normal;
  }

  p {
    margin: 0 0 0.375rem;

    @media ${breakpoints.lg} {
      margin-bottom: 0.5rem;
    }
  }

  strong {
    font-weight: 600;
  }
`;

const Section5 = styled.div`
  padding-bottom: 1.875rem;

  @media ${breakpoints.lg} {
    padding-bottom: 6.25rem;
  }
`;

const Section5Column = styled.div`
  flex: 0 0 auto;
  max-width: 12.5rem;
  padding: 0 1.5625rem;
  width: 50%;

  @media ${breakpoints.sm} {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    max-width: none;
    padding: 0;
    width: 100%;
  }

  @media ${breakpoints.lg} {
    width: auto;
  }
`;

const Section5Item = styled.div`
  margin-bottom: 1.875rem;
  text-align: center;

  @media ${breakpoints.sm} {
    flex: 1 1 auto;
    max-width: 9.375rem;
    padding: 0 1.25rem;
  }

  @media ${breakpoints.lg} {
    max-width: none;
  }
`;

const Section5List = styled.div`
  display: flex;
  margin: 0 -1.5625rem;

  @media ${breakpoints.sm} {
    display: block;
    margin: 0 1.25rem;
  }

  @media ${breakpoints.lg} {
    display: flex;
    margin: 0;
  }
`;

const Section6 = styled.div``;

const Section6Container = styled.div`
  @media ${breakpoints.lg} {
    display: flex;
    margin-top: -0.625rem;
  }
`;

const Section6Image = styled.div`
  @media ${breakpoints.lg} {
    order: 2;
    width: 45%;
  }
`;

const Section6Text = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 3.75rem;

  @media ${breakpoints.lg} {
    font-size: 1.0625rem;
    line-height: 1.5625rem;
    margin-top: 0;
    order: 1;
    padding-right: 6.25rem;
    width: 55%;
  }

  p {
    margin: 0 0 0.375rem;

    @media ${breakpoints.lg} {
      margin-bottom: 0.5rem;
    }
  }
`;

const SectionHeading = styled.h2`
  font-family: ${fonts.pko};
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.25rem;
  margin: 0;
  padding: 2.5rem 0;

  @media ${breakpoints.lg} {
    font-size: 1.875rem;
    line-height: 2.125rem;
    padding: 5.625rem 0 3.75rem;
  }
`;

const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const LetsFintech = () => (
  <StyledSection>
    <Container>
      <Heading icon={<FaSignature />} title="letsfintech.heading" />

      <Section1>
        <Section1Image>
          <LetsFintech1 />
        </Section1Image>

        <Section1Content>
          <Section1Text>
            <FormattedHTMLMessage id="letsfintech.section1.text" />
          </Section1Text>

          <Section1Button>
            <Button
              href={useIntl().formatMessage({
                id: 'letsfintech.section1.link',
              })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="letsfintech.section1.button" />
              <ButtonIcon />
            </Button>
          </Section1Button>
        </Section1Content>
      </Section1>

      <Separator />

      <Section2>
        <Section2Heading>
          <FormattedHTMLMessage id="letsfintech.section2.heading" />
        </Section2Heading>

        <Section2List>
          <Section2Item>
            <Section2Icon>
              <LetsFintechIcon1 />
            </Section2Icon>

            <Section2Text>
              <FormattedHTMLMessage id="letsfintech.section2.item1" />
            </Section2Text>
          </Section2Item>

          <Section2Item>
            <Section2Icon>
              <LetsFintechIcon2 />
            </Section2Icon>

            <Section2Text>
              <FormattedHTMLMessage id="letsfintech.section2.item2" />
            </Section2Text>
          </Section2Item>

          <Section2Item>
            <Section2Icon>
              <LetsFintechIcon3 />
            </Section2Icon>

            <Section2Text>
              <FormattedHTMLMessage id="letsfintech.section2.item3" />
            </Section2Text>
          </Section2Item>

          <Section2Item>
            <Section2Icon>
              <LetsFintechIcon4 />
            </Section2Icon>

            <Section2Text>
              <FormattedHTMLMessage id="letsfintech.section2.item4" />
            </Section2Text>
          </Section2Item>
        </Section2List>
      </Section2>

      <Separator />

      <Section3>
        <Section3Content>
          <Section3Heading>
            <FormattedHTMLMessage id="letsfintech.section3.heading" />
          </Section3Heading>

          <Section3Text>
            <FormattedHTMLMessage id="letsfintech.section3.text" />
          </Section3Text>

          <Section3Row>
            <Section3Column>
              <Section3ColumnText>
                <FormattedHTMLMessage id="letsfintech.section3.button1.text" />
              </Section3ColumnText>
              <Button
                href={useIntl().formatMessage({
                  id: 'letsfintech.section3.button1.link',
                })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedHTMLMessage id="letsfintech.section3.button1.button" />
                <ButtonIcon />
              </Button>
            </Section3Column>

            <Section3Column>
              <Section3ColumnText>
                <FormattedHTMLMessage id="letsfintech.section3.button2.text" />
              </Section3ColumnText>
              <Button href="mailto:vc@pkotfi.pl">
                <FormattedHTMLMessage id="letsfintech.section3.button2.button" />
                <ButtonIcon />
              </Button>
            </Section3Column>
          </Section3Row>
        </Section3Content>

        <Section3Info>
          <Rocket>
            <RocketIcon />
          </Rocket>

          <Section3InfoList>
            <Section3InfoItem>
              <strong>
                <FormattedHTMLMessage id="letsfintech.section3.item1.heading" />
              </strong>

              <FormattedHTMLMessage id="letsfintech.section3.item1.text" />
            </Section3InfoItem>

            <Section3InfoItem>
              <strong>
                <FormattedHTMLMessage id="letsfintech.section3.item2.heading" />
              </strong>

              <FormattedHTMLMessage id="letsfintech.section3.item2.text" />
            </Section3InfoItem>

            <Section3InfoItem>
              <strong>
                <FormattedHTMLMessage id="letsfintech.section3.item3.heading" />
              </strong>

              <FormattedHTMLMessage id="letsfintech.section3.item3.text" />
            </Section3InfoItem>
          </Section3InfoList>
        </Section3Info>
      </Section3>

      <Separator />

      <Section4>
        <Section4Image>
          <LetsFintechGrzegorzPawlicki />
        </Section4Image>

        <Section4Text>
          <FormattedHTMLMessage id="letsfintech.section4.text" />
        </Section4Text>
      </Section4>

      <Separator />

      <Section5>
        <SectionHeading>
          <FormattedHTMLMessage id="letsfintech.section5.heading" />
        </SectionHeading>

        <Section5List>
          <Section5Column>
            <Section5Item>
              <ResponsiveImage src={logo1} />
            </Section5Item>

            <Section5Item>
              <ResponsiveImage src={logo2} />
            </Section5Item>

            <Section5Item>
              <ResponsiveImage src={logo3} />
            </Section5Item>

            <Section5Item>
              <ResponsiveImage src={logo5} />
            </Section5Item>
          </Section5Column>

          <Section5Column>
            <Section5Item>
              <ResponsiveImage src={logo4} />
            </Section5Item>

            <Section5Item>
              <ResponsiveImage src={logo6} />
            </Section5Item>

            <Section5Item>
              <ResponsiveImage src={logo7} />
            </Section5Item>
          </Section5Column>
        </Section5List>
      </Section5>

      <Separator />

      <Section6>
        <SectionHeading>
          <FormattedHTMLMessage id="letsfintech.section6.heading" />
        </SectionHeading>

        <Section6Container>
          <Section6Image>
            <LetsFintechTeam />
          </Section6Image>

          <Section6Text>
            <FormattedHTMLMessage id="letsfintech.section6.text" />
          </Section6Text>
        </Section6Container>
      </Section6>
    </Container>
  </StyledSection>
);

export default withLayout(LetsFintech);
